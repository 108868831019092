<template>
  <el-popover placement="bottom-start" title="添加流程节点" width="350" trigger="click" disabled>
    <div class="node-select">
      <div v-for="i in typeList" :key="i.type" @click="addNode(i.type)">
        <i :class="i.icon || 'el-icon-s-check'" style="color:rgb(255, 148, 62);"></i>
        <span>{{i.name}}</span>
      </div>
    </div>
    <el-button icon="el-icon-plus" slot="reference" type="primary" size="small" circle disabled></el-button>
  </el-popover>
</template>

<script>
export default {
  name: "InsertButton",
  components: {},
  data() {
    return {
      typeList: [
        {
          type: 'APPROVAL',
          name: '审批人',
          icon: 'el-icon-s-check'
        },
        {
          type: 'CONDITIONS',
          name: '条件节点',
          icon: 'el-icon-share'
        },
        // {
        //   type: 'CC',
        //   name: '抄送人',
        //   icon: 'el-icon-s-promotion'
        // },
        // {
        //   type: 'CONCURRENTS',
        //   name: '并行分支',
        //   icon: 'el-icon-s-operation'
        // },
        // {
        //   type: 'DELAY',
        //   name: '延迟等待',
        //   icon: 'el-icon-time'
        // },
        // {
        //   type: 'TRIGGER',
        //   name: '触发器',
        //   icon: 'el-icon-set-up'
        // },
      ]
    }
  },
  computed:{
  },
  mounted() {
  },
  methods: {
    addNode(type) {
      this.$emit('insertNode', type)
    },
  }
}
</script>

<style lang="less" scoped>
.node-select{
  div{
    display: inline-block;
    margin: 5px 5px;
    cursor: pointer;
    padding: 10px 15px;
    border: 1px solid #F8F9F9;
    background-color: #F8F9F9;
    border-radius: 10px;
    width: 130px;
    position: relative;
    span{
      position: absolute;
      left: 65px;
      top: 18px;
    }
    &:hover{
      background-color: #fff;
      box-shadow: 0 0 8px 2px #d6d6d6;
    }
    i{
      font-size: 25px;
      padding: 5px;
      border: 1px solid #dedfdf;
      border-radius: 14px;
    }
  }
}
</style>
