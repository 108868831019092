<template>
  <div>
    <component :is="(selectNode.type||'').toLowerCase()" :config="selectNode.props"/>
  </div>
</template>

<script>
//导入所有节点组件
const context = require.context("../../process/config/", true, /\.vue$/);
const cmps = {};
context.keys().forEach((key) => {
  const mycomponent = context(key).default;
  cmps[mycomponent.name] = mycomponent;
});

export default {
  name: "NodeConfig",
  components: {
    ...cmps
  },
  data() {
    return {
    }
  },
  computed: {
    selectNode() {
      return this.$store.state.workflow.selectedNode
    },
  },
  methods: {}
}
</script>

<style lang="less" scoped>

</style>
