import { ajax } from "@/api/request";

// 审批流设置相关
// 审核动作
export const getApproveActionList = function(data) {
  return ajax({
    url: '/approve/v1/getApproveActionList',
    method: 'get',
    params: data
  });
}

// 审核条件
export const getApproveConditionObjectList = function(data) {
  return ajax({
    url: '/approve/v1/getApproveConditionObjectList',
    method: 'get',
    params: data
  });
}

// // 审批显示的字段
// export const getApproveListField = function(data) {
//   return ajax({
//     url: '/approve/v1/getApproveListField',
//     method: 'get',
//     params: data
//   });
// }

// 审核/抄送人
export const getApproveUserList = function(data) {
  return ajax({
    url: '/approve/v1/getApproveUserList',
    method: 'get',
    params: data
  });
}

// 新建审批流
export const createApproveTemplate = function(data) {
  return ajax({
    url: '/approve/v1/createApproveTemplate',
    method: 'post',
    data
  });
}

// 编辑审批流
export const updateApproveTemplate = function(data) {
  return ajax({
    url: '/approve/v1/updateApproveTemplate',
    method: 'post',
    data
  });
}

// 审批流列表
export const getApproveTemplateList = function(data) {
  return ajax({
    url: '/approve/v1/getApproveTemplateList',
    method: 'get',
    params: data
  });
}

// 审批列表
export const getApproveTemplateDetail = function(data) {
  return ajax({
    url: '/approve/v1/getApproveTemplateDetail',
    method: 'get',
    params: data
  });
}

// 启用审批
export const startApproveTemplate = function(data) {
  return ajax({
    url: '/approve/v1/startApproveTemplate',
    method: 'get',
    params: data
  });
}

// 停用审批
export const stopApproveTemplate = function(data) {
  return ajax({
    url: '/approve/v1/stopApproveTemplate',
    method: 'get',
    params: data
  });
}

// 删除审批
export const removeApproveTemplate = function(data) {
  return ajax({
    url: '/approve/v1/removeApproveTemplate',
    method: 'get',
    params: data
  });
}

// 审批排序
export const sortApproveTemplate = function(data) {
  return ajax({
    url: '/approve/v1/sortApproveTemplate',
    method: 'post',
    data
  });
}
