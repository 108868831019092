<template>
  <div>
    <el-button type="text" @click="addItem">+ 添加触发条件</el-button>
    <div class="mb-20 flex flex-center relative">
      <div class="text-center param-label" v-if="list.length > 1">
        条件<br />（且）
      </div>
      <div class="flex-1" :class="{ 'param-list': list.length > 1 }">
        <div
          class="param-item flex flex-center"
          v-for="(o, oi) in list"
          :key="oi"
        >
          <el-select
            class="mr-10"
            v-model="o.conditionObject"
            size="small"
            placeholder="请选择字段"
            style="width: 150px"
            @change="changeObject($event, oi)"
          >
            <el-option
              v-for="o in options[oi].conditionObjects"
              :key="o.code"
              :value="o.code"
              :label="o.msg"
            ></el-option>
          </el-select>
          <el-select
            class="mr-10"
            v-model="o.conditionOperate"
            size="small"
            placeholder="请选择条件"
            style="width: 150px"
            @change="changeOperation(o, oi)"
          >
            <el-option
              v-for="o in options[oi].conditionOperations"
              :key="o.code"
              :value="o.code"
              :label="o.msg"
            ></el-option>
          </el-select>
          <div class="flex-1">
            <el-cascader
              v-if="o.valueType == 'SPECIAL' && options[oi].conditionValues.length > 0"
              :ref="'conditionValueCascader' + oi"
              class="mr-10"
              size="small"
              placeholder="请选择字段"
              style="width: 250px"
              v-model="o.valueFmt"
              :options="options[oi].conditionValues"
              :props="{
                multiple: true,
                label: 'value',
                value: 'key',
                children: 'childOperations',
              }"
              :show-all-levels="false"
              filterable
              collapse-tags
              clearable
              @change="handleChangeValue($event, oi, o)"
            ></el-cascader>

            <el-input
              v-if="o.valueType == 'NUMBER'"
              type="number"
              size="small"
              onKeypress="return(/[\d\.]/.test(String.fromCharCode(event.keyCode)))"
              placeholder="请输入数字"
              v-model="o.value"
            ></el-input>

            <el-date-picker
              v-if="o.valueType == 'DATE'"
              v-model="o.value"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="请选择日期"
            ></el-date-picker>
            <span>{{ o.unit }}</span>
          </div>

          <span
            class="el-icon-delete cursor-point font-size-20"
            @click="deleteItem(oi)"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getApproveConditionObjectList } from "@/api/approve";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: [
        {
          conditionObjects: [], // 条件对象
          conditionOperations: [], // 条件操作符
          conditionValues: [], // 条件可操作值
        },
      ], // 下拉选项
    };
  },
  computed: {
    setup() {
      return this.$store.state.workflow.design;
    },
    list: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  watch: {
    "setup.approveObject"() {
      this.options = [
        {
          conditionObjects: [],
          conditionOperations: [],
          conditionValues: [],
        },
      ];
      this.getObjects();
    },
    "value.length"(val) {
      console.log("value.length", val);
      this.getDetailOption();
    },
  },
  created() {
    this.getDetailOption();
  },
  methods: {
    async getDetailOption() {
      this.value.map((o, i) => {
        if (i > 0) {
          this.options.push({
            conditionObjects: [],
            conditionOperations: [],
            conditionValues: [],
          });
        }
      });
      await this.getObjects();
      // 编辑状态下获取各个下拉框的值
      this.value.map((o, i) => {
        if (i > 0) {
          this.options[i].conditionObjects = JSON.parse(
            JSON.stringify(this.options[0].conditionObjects)
          );
        }
        this.getOperations(o.conditionObject, i);
        this.getValues(o, i);
      });
    },
    async getObjects() {
      if (!this.setup.approveObject) return;
      const res = await getApproveConditionObjectList({
        approveObject: this.setup.approveObject,
        orgId: this.$store.state.userInfo.fkOrgId,
      });
      this.options.map((o) => {
        o.conditionObjects = res || [];
      });
    },
    getOperations(val, index) {
      const item =
        this.options[index].conditionObjects.find((o) => o.code == val) || {};
      console.log("getOperations", val, index, item);
      this.options[index].conditionOperations = item.operationList || [];
    },
    getValues(item, index) {
      const item0 =
        this.options[index].conditionOperations.find(
          (o) => o.code == item.conditionOperate
        ) || {};
      console.log("getValues", item.conditionOperate, index, item0);
      this.options[index].conditionValues = item0.valueList;
    },
    changeObject(val, index) {
      this.getOperations(val, index);
      this.list[index].conditionObjectName = this.options[
        index
      ].conditionObjects.find((o) => o.code == val).msg;
      this.list[index].conditionOperate = "";
      this.list[index].conditionOperateName = "";
      this.options[index].conditionValues = [];
      this.list[index].valueFmt = [];
    },
    changeOperation(item, index) {
      const y =
        this.options[index].conditionOperations.find(
          (o) => o.code == item.conditionOperate
        ) || {};
      const childValueType = y.childValueType || "";
      if (childValueType === "SPECIAL") {
        this.getValues(item, index);
      }
      this.list[index].conditionOperateName = y.msg;
      this.$set(item, "valueType", childValueType);
      this.$set(item, "unit", y.childContent.replaceAll("%s", ""));
      this.list[index].valueFmt = [];
      this.list[index].value = "";
    },
    handleChangeValue(val, index, item) {
      this.$nextTick(() => {
        let _data =
          this.$refs["conditionValueCascader" + index][0].getCheckedNodes(true);
        // console.log(_data)
        let value = [];
        _data.map((o) => {
          const rootKey = o.pathNodes[0].data.key;
          let index = value.findIndex((v) => v.key == rootKey);
          if (index < 0) {
            value.push({
              key: o.pathNodes[0].data.key,
              value: o.pathNodes[0].data.value,
              childOperations: o.pathNodes[1] ? [] : null,
            });
            index = value.length - 1;
          }
          if (o.pathNodes[1]) {
            value[index].childOperations.push({
              key: o.pathNodes[1].data.key,
              value: o.pathNodes[1].data.value,
              childOperations: o.pathNodes[2] ? [o.pathNodes[2].data] : null,
            });
          }
        });
        console.log(value);
        this.$set(item, "value", JSON.stringify(value));
      })
    },
    addItem() {
      if (!this.setup.approveObject)
        return this.$message.warning("请先选择基础信息-触发场景-主体对象");
        
      if (this.list.length > 0) {
        // 如果当前没有条件 则option不用新增
        this.options.push({
          conditionObjects: JSON.parse(
            JSON.stringify(this.options[0].conditionObjects)
          ),
          conditionOperations: [],
          conditionValues: [],
        });
      }
      this.list.push({
        conditionObject: "",
        conditionObjectName: "",
        conditionOperate: "",
        conditionOperateName: "",
        valueType: "",
        value: "",
      });
    },
    deleteItem(index) {
      if (this.options.length > 1) {
        // 保留一个options元素当做模板
        this.options.splice(index, 1);
      }
      this.list.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.param-label {
  &::before {
    content: "";
    border-left: 1px solid #cacaca;
    position: absolute;
    bottom: 16px;
    top: 16px;
    left: 42px;
  }
}
.param-list {
  padding-left: 50px;
  .param-item {
    position: relative;
    &::before {
      content: "";
      width: 50px;
      border-top: 1px solid #cacaca;
      position: absolute;
      left: -50px;
      top: 16px;
    }
  }
  .param-item + .param-item {
    margin-top: 10px;
  }
}
</style>