<template>
  <div class="page-content">
    <FormProcessDesign style="height: 100%"></FormProcessDesign>
  </div>
</template>

<script>
import Vue from 'vue'
import Ellipsis from '@/components/workflow/common/Ellipsis'
Vue.use(Ellipsis);

import FormProcessDesign from '@/components/workflow/FormProcessDesign'

export default {
  components: {
    FormProcessDesign
  },
  data() {
    return {
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.page-content{
  box-sizing: border-box;
  height: 100%;
}
</style>