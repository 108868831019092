<template>
  <div class="default-node">
    <div class="mb-20">
      <div class="label">
        <span class="c-red">*</span>
        节点名称
      </div>
      <el-input size="small" v-model="selectedNode.name" placeholder="请输入节点名称"></el-input>
    </div>
    <div class="mb-20">
      <div class="label">
        <span class="c-red">*</span>
        审批类型
      </div>
      <el-select class="select" size="small" v-model="nodeProps.mode" placeholder="请选择审批类型">
        <el-option value="OR" label="或签（一人通过即可）"></el-option>
        <el-option value="AND" label="会签（所有人通过才可）"></el-option>
      </el-select>
    </div>
    <div class="mb-20">
      <div class="label">
        <span class="c-red">*</span>
        审批人
      </div>
      <el-select class="select mb-10" size="small" v-model="nodeProps.userType" placeholder="请选择审批人" @change="handleChangeUserType" disabled>
        <el-option v-for="t in userTypeList" :key="t.key" :value="t.key" :label="t.value"></el-option>
      </el-select>
      <el-select class="select" size="small" multiple clearable v-model="nodeProps.role" placeholder="请选择审批人" v-if="nodeProps.userType == 'ROLE'" @change="handleChangeRole" disabled>
        <el-option v-for="o in userList" :key="o.key" :value="o.key" :label="o.value"></el-option>
      </el-select>
      <el-cascader
        v-if="nodeProps.userType == 'APPOINTOR'"
        :ref="'conditionValueCascader'"
        class="select" size="small" placeholder="请选择字段"
        v-model="nodeProps.user"
        :options="userList"
        :props="{
          multiple: true,
          label: 'value',
          value: 'key',
          children: 'childOperations'
        }"
        :show-all-levels="false"
        filterable
        clearable disabled
        @change="handleChangeValue"
      ></el-cascader>
    </div>
    <!-- <div>
      <el-checkbox>该节点驳回后，再次提交直接返回至此节点</el-checkbox>
    </div> -->
    <div class="mb-20">
      <el-checkbox v-model="nodeProps.autoPass">当前节点审批人与流程发起人为同一个人，自动通过</el-checkbox>
    </div>
  </div>
</template>

<script>
import {
  getApproveUserList
} from '@/api/approve'

export default {
  name: "Approval",
  components: {},
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      userTypeList: [],
      // userList: []
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.workflow.selectedNode
    },
    nodeProps() {
      return this.$store.state.workflow.selectedNode.props
    },
    userList() {
      const item = this.userTypeList.find(o => o.key == this.nodeProps.userType) || {}
      return item.childOperations || []
    },
  },
  mounted() {
    this.getApproveUserList()
  },
  methods: {
    getApproveUserList() {
      getApproveUserList({
        orgId: this.$store.state.userInfo.fkOrgId
      }).then(res => {
        this.userTypeList = res
      })
    },
    handleChangeUserType() {
      const item = this.userTypeList.find(o => o.key == this.nodeProps.userType) || {}
      this.nodeProps.userTypeValue = item.value || ''
      this.nodeProps.role = []
      this.nodeProps.user = []
      this.nodeProps.userConfig = {
        userList: []
      }
      if (this.nodeProps.userType == 'SUPERIOR') {
        this.nodeProps.userConfig = {
          userList: [item]
        }
      } else {
        this.nodeProps.userConfig = {
          userList: []
        }
      }
      console.log(this.nodeProps.userConfig)
    },
    handleChangeRole() {
      const childOperations = this.userList.filter(o => this.nodeProps.role.indexOf(o.key) > -1)
        if (childOperations.length > 0) {
        this.nodeProps.userConfig = {
          userList: [{
            key: this.nodeProps.userType,
            value: this.nodeProps.userTypeValue,
            childOperations: childOperations
          }]
        }
      } else {
        this.nodeProps.userConfig = {
          userList: []
        }
      }
      console.log(this.nodeProps.userConfig)
    },
    handleChangeValue() {
      this.$nextTick(() => {
        let _data = this.$refs['conditionValueCascader'].getCheckedNodes(true);
        // console.log(_data)
        let value = []
        _data.map(o => {
          const rootKey = o.pathNodes[0].data.key
          let index = value.findIndex(v => v.key == rootKey)
          if (index < 0) {
            value.push({
              key: o.pathNodes[0].data.key,
              value: o.pathNodes[0].data.value,
              childOperations: o.pathNodes[1] ? [] : null
            })
            index = value.length - 1
          }
          if (o.pathNodes[1].data) {
            value[index].childOperations.push(o.pathNodes[1].data)
          }
        })
        if (value && value.length > 0) {
          this.nodeProps.userConfig = {
            userList: [{
              key: this.nodeProps.userType,
              value: this.nodeProps.userTypeValue,
              childOperations: value
            }]
          }
        } else {
          this.nodeProps.userConfig = {
            userList: []
          }
        }
        console.log(this.nodeProps.userConfig)
      })
    },
  }
}
</script>

<style lang="less" scoped>
.default-node{
  color: #72767b;
  .select{
    width: 100%;
  }
  .label{
    margin-bottom: 10px;
  }
}
</style>
