<template>
  <div :class="{'node': true, 'node-error-state': showError}">
    <div :class="{'node-body': true, 'error': showError}">
      <div class="node-body-left" @click="$emit('leftMove')" v-if="level > 1 && !isOther">
        <i class="el-icon-arrow-left"></i>
      </div>
      <div class="node-body-main" @click="selected()">
        <div class="node-body-main-header">
          <ellipsis class="title" hover-tip :content="config.name ? config.name : ('条件' + level)"/>
          <span class="level" v-if="!isOther">优先级{{ level }}</span>
          <span class="option" v-if="!isOther">
            <el-tooltip effect="dark" content="复制条件" placement="top">
              <i class="el-icon-copy-document" @click.stop="$emit('copy')"></i>
            </el-tooltip>
            <i class="el-icon-close" @click.stop="$emit('delNode')"></i>
          </span>
        </div>
        <div class="node-body-main-content">
          <span class="placeholder" v-if="(content || '').trim() === ''">{{
            isOther ? "其他条件进入此流程" : placeholder
          }}</span>
          <ellipsis hoverTip :row="4" :content="content" v-else/>
        </div>
      </div>
      <div class="node-body-right" @click="$emit('rightMove')"
           v-if="level < size && !isOther">
        <i class="el-icon-arrow-right"></i>
      </div>
      <div class="node-error" v-if="showError">
        <el-tooltip effect="dark" :content="errorInfo" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <div class="node-footer">
      <div class="btn">
        <insert-button @insertNode="type => $emit('insertNode', type)"></insert-button>
      </div>
    </div>
  </div>
</template>

<script>
import InsertButton from '../../InsertButton.vue'

export default {
  name: "Condition",
  components: {InsertButton},
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    //索引位置
    level: {
      type: Number,
      default: 1
    },
    //条件数
    size: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      placeholder: '请设置条件',
      errorInfo: '',
      showError: false
    }
  },
  computed: {
    isOther() {
      return this.level == this.size && this.size != 0
    },
    content() {
      const conditions = this.config.props.conditionConfig.condition
      let confitions = []
      conditions.forEach(o => {
        confitions.push(`${o.conditionObjectName} ${o.conditionOperateName} ${this.getOrdinaryConditionContent(o.value)}`)
      })
      //构建最终描述
      return String(confitions).replaceAll(',', ' 且 ')
    }
  },
  methods: {
    selected() {
      if (this.isOther) return this.$message.warning('默认条件节点不可操作')
      this.$emit('selected')
    },
    getOrdinaryConditionContent(value) {
      let arr = []
      const valueArr = JSON.parse(value)
      valueArr.map(o => {
        if (o.childOperations?.length > 0) {
          o.childOperations.map(v => {
            if (v.childOperations?.length > 0) {
              arr = arr.concat(v.childOperations.map(vv => vv.value))
            } else {
              arr.push(v.value)
            }
          })
        } else {
          arr = arr.concat([o.value])
        }
      })
      return arr.join('、')
    },
    //校验数据配置的合法性
    validate(err) {
      this.showError = false
      if (!this.config.name){
        this.showError = true
        this.errorInfo = '请设置节点名称'
        err.push(`节点名称不可为空`)
      }
      console.log('condition children', this.config.children)
      if (!(this.level == this.size && this.size != 0) && !this.config.children?.id) {
        this.showError = true
        this.errorInfo = '条件分支后不能为空'
        err.push(`条件分支后不能为空`)
        return !this.showError
      }

      const props = this.config.props
      // 除了"其他"条件分支节点，其他的条件都不可为空
      if (!this.isOther) {
        if (props.conditionConfig.condition.length <= 0){
          this.showError = true
          this.errorInfo = '请设置分支条件'
          err.push(`${this.config.name} 未设置条件`)
        }
      }
      
      const items = props.conditionConfig.condition.filter(o => {
        if (o.valueType == 'SPECIAL') {
          return !(o.conditionObject && o.conditionOperate && o.valueFmt.length > 0)
        } else if (o.valueType == 'NUMBER') {
          return !(o.conditionObject && o.conditionOperate && o.value !== '')
        } else if (o.valueType == 'DATE') {
          return !(o.conditionObject && o.conditionOperate && o.value !== '')
        } else {
          return !(o.conditionObject && o.conditionOperate)
        }
      })
      console.log(items)
      if (items.length > 0) {
        this.showError = true
        this.errorInfo = '条件不完整'
        err.push(`${this.config.name} 条件不完整`)
      }
      return !this.showError;
    },
  },
};
</script>

<style lang="less" scoped>


.node-error-state {
  .node-body {
    box-shadow: 0px 0px 5px 0px #F56C6C !important;
  }
}

.node {
  padding: 30px 55px 0;
  width: 220px;

  .node-body {
    cursor: pointer;
    min-height: 80px;
    max-height: 120px;
    position: relative;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 0px 5px 0px #d8d8d8;

    &:hover {
      .node-body-left, .node-body-right {
        i {
          display: block !important;
        }
      }

      .node-body-main {
        .level {
          display: none !important;
        }

        .option {
          display: inline-block !important;
        }
      }

      box-shadow: 0px 0px 3px 0px #1890FF;
    }

    .node-body-left, .node-body-right {
      display: flex;
      align-items: center;
      position: absolute;
      height: 100%;

      i {
        display: none;
      }

      &:hover {
        background-color: #ececec;
      }
    }

    .node-body-left {
      left: 0;
    }

    .node-body-right {
      right: 0;
      top: 0;
    }

    .node-body-main {
      //position: absolute;
      width: 188px;
      margin-left: 17px;
      display: inline-block;

      .node-body-main-header {
        padding: 10px 0px 5px;
        font-size: xx-small;
        position: relative;

        .title {
          color: #15bca3;
          display: inline-block;
          height: 14px;
          width: 125px;
        }

        .level {
          position: absolute;
          right: 15px;
          color: #888888;
        }

        .option {
          position: absolute;
          right: 0;
          display: none;
          font-size: medium;

          i {
            color: #888888;
            padding: 0 3px;
          }
        }
      }

      .node-body-main-content {
        padding: 6px;
        color: #656363;
        font-size: 14px;

        i {
          position: absolute;
          top: 55%;
          right: 10px;
          font-size: medium;
        }

        .placeholder {
          color: #8c8c8c;
        }
      }
    }

    .node-error {
      position: absolute;
      right: -40px;
      top: 20px;
      font-size: 25px;
      color: #F56C6C;
    }
  }

  .node-footer {
    position: relative;

    .btn {
      width: 100%;
      display: flex;
      height: 70px;
      padding: 20px 0 32px;
      justify-content: center;
    }

    /deep/ .el-button {
      height: 32px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      margin: auto;
      width: 2px;
      height: 100%;
      background-color: #CACACA;
    }
  }
}
</style>
