<template>
  <el-container>
    <el-header style="background: white">
      <layout-header
        v-model="activeSelect"
        :title="title"
        @validate="validate"
        @publish="publishProcess"
        @exit="goBack"
      ></layout-header>
    </el-header>
    <div class="layout-body">
      <form-base-setting
        ref="baseSetting"
        v-show="activeSelect === 'baseSetting'"
      />
      <process-design
        ref="processDesign"
        v-show="activeSelect === 'processDesign'"
      />
      <!-- <form-pro-setting ref="proSetting" v-show="activeSelect === 'proSetting'"/> -->
    </div>
  </el-container>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import LayoutHeader from "./LayoutHeader";
import FormBaseSetting from "./layout/FormBaseSetting";
import ProcessDesign from "./layout/ProcessDesign";
// import FormProSetting from './layout/FormProSetting'
import DefaultProps from "./layout/process/DefaultNodeProps";
import {
  createApproveTemplate,
  updateApproveTemplate,
  getApproveTemplateDetail,
} from "@/api/approve";

const deepCopy = function (obj) {
  return JSON.parse(JSON.stringify(obj));
};

const params = {
  templateId: "",
  approveName: "", // 审批流名称
  formNumber: "", // 审批流编号
  approveDesc: "", // 审批流描述
  approveObject: "",
  approveAction: "", // 触发动作
  approveActionField: "", // 被操作字段
  approveConditionList: [], // 触发条件
  // listFieldList: [], // 审批显示字段
  allowRevoke: false, // 是否允许发起人撤回
  nodeFinishNotice: false, // 是否将每个节点结果发给发起人
  allFinishNotice: false, // 是否在审批全流程结束后将结果发给发起人
  timeLimitNum: "", // 流程期限
  // timeLimitUnit: 0, // 流程期限时间单位
  processDetail: {
    // 审批流
    id: "root",
    parentId: null,
    type: "ROOT",
    name: "流程开始",
    props: {},
    children: {
      id: "node_" + uuidv4().replace(/-/g, ""),
      parentId: "root",
      name: "审批人",
      type: "APPROVAL",
      props: deepCopy(DefaultProps.APPROVAL_PROPS),
      children: {},
    },
  },
  passAction: {
    copyFlag: false,
    copyToUsers: [],
    userType: "",
    role: [],
    user: [],
    msgFlag: false,
    msgContent: "",
    msgControllableContent: "",
  },
  rejectAction: {
    copyFlag: false,
    copyToUsers: [],
    userType: "",
    role: [],
    user: [],
  },
};

export default {
  name: "FormProcessDesign",
  components: { LayoutHeader, FormBaseSetting, ProcessDesign },
  data() {
    return {
      isNew: false,
      templateId: "",
      title: undefined,
      activeSelect: "baseSetting",
      validComponents: ["baseSetting", "processDesign"],
    };
  },
  watch: {},
  computed: {
    setup() {
      return this.$store.state.workflow.design;
    },
  },
  created() {
    this.templateId = this.$route.query.templateId;
    console.log("编辑审批流", this.templateId);
    //判断传参，决定是新建还是加载原始数据
    this.loadInitFrom();
    if (this.templateId) {
      this.loadFormInfo();
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    loadInitFrom() {
      this.$store.commit("workflow/setDesign", params);
    },
    loadFormInfo() {
      // 获取审批流详情
      getApproveTemplateDetail({
        templateId: this.templateId,
      }).then((res) => {
        if (res) {
          const processDetail = JSON.parse(res.processDetail);
          const detail = {
            ...res,
            templateId: this.isNew ? "" : this.templateId,
            approveName: this.isNew
              ? res.approveName + "（副本）"
              : res.approveName,
            timeLimitNum: res.timeLimitNum < 0 ? "" : res.timeLimitNum,
            processDetail: processDetail,
            approveConditionList: res.approveConditionList.map((o) => {
              const value = JSON.parse(o.value);
              let valueFmt = [];
              if (Array.isArray(value)) {
                value.map((v) => {
                  if (v.childOperations && v.childOperations.length > 0) {
                    v.childOperations.map((vv) => {
                      if (vv.childOperations && vv.childOperations.length > 0) {
                        vv.childOperations.map((vvv) => {
                          valueFmt.push([v.key, vv.key, vvv.key]);
                        });
                      } else {
                        valueFmt.push([v.key, vv.key]);
                      }
                    });
                  } else {
                    valueFmt.push([v.key]);
                  }
                });
              }
              return {
                ...o,
                valueFmt: [valueFmt],
              };
            }),
          };
          this.title = detail.approveName;
          // 渲染抄送人选择框数据
          if (detail.passAction.copyToUsers.length) {
            const userType = detail.passAction.copyToUsers[0].key;
            const userTypeValue = detail.passAction.copyToUsers[0].value;
            detail.passAction.userType = userType;
            detail.passAction.userTypeValue = userTypeValue;
            if (userType == "ROLE") {
              detail.passAction.role =
                detail.passAction.copyToUsers[0].childOperations.map(
                  (o) => o.key
                );
            } else if (userType == "APPOINTOR") {
              let user = [];
              detail.passAction.copyToUsers[0].childOperations.map((o) => {
                o.childOperations.map((v) => {
                  user.push([o.key, v.key]);
                });
              });
              detail.passAction.user = user;
            }
          }
          if (detail.rejectAction.copyToUsers.length) {
            const userType = detail.rejectAction.copyToUsers[0].key;
            const userTypeValue = detail.rejectAction.copyToUsers[0].value;
            detail.rejectAction.userType = userType;
            detail.rejectAction.userTypeValue = userTypeValue;
            if (userType == "ROLE") {
              detail.rejectAction.role =
                detail.rejectAction.copyToUsers[0].childOperations.map(
                  (o) => o.key
                );
            } else if (userType == "APPOINTOR") {
              let user = [];
              detail.rejectAction.copyToUsers[0].childOperations.map((o) => {
                o.childOperations.map((v) => {
                  user.push([o.key, v.key]);
                });
              });
              detail.rejectAction.user = user;
            }
          }
          console.log("审批流详情", detail);
          this.$store.commit("workflow/setDesign", detail);
        }
      });
    },
    validate(callback) {
      const index = this.validComponents.indexOf(this.activeSelect);
      const err = this.$refs[this.validComponents[index]].validate();
      console.log(err);
      if (err.length > 0) {
        this.$message.warning(err[0]);
      } else {
        callback();
      }
    },
    publishProcess() {
      console.log(this.setup);
      this.doPublish();
    },
    doPublish() {
      let processNew = JSON.parse(JSON.stringify(this.setup.processDetail));
      let template = {
        orgId: this.$store.state.userInfo.fkOrgId,
        approveName: this.setup.approveName,
        approveDesc: this.setup.approveDesc,
        approveObject: this.setup.approveObject,
        approveAction: this.setup.approveAction,
        approveActionField: this.setup.approveActionField,
        approveConditionList: this.setup.approveConditionList,
        // listFieldList: this.setup.listFieldList,
        allowRevoke: this.setup.allowRevoke,
        nodeFinishNotice: this.setup.nodeFinishNotice,
        allFinishNotice: this.setup.allFinishNotice,
        timeLimitNum: this.setup.timeLimitNum,
        // timeLimitUnit: this.setup.timeLimitUnit,
        processDetail: JSON.stringify(processNew),
        passAction: {
          ...this.setup.passAction,
          copyToUsers: this.setup.passAction.copyFlag
            ? this.setup.passAction.copyToUsers
            : [],
        },
        rejectAction: {
          ...this.setup.rejectAction,
          copyToUsers: this.setup.rejectAction.copyFlag
            ? this.setup.rejectAction.copyToUsers
            : [],
        },
      };
      console.log("提交参数", template);
      if (!this.isNew) {
        updateApproveTemplate({
          templateId: this.templateId,
          ...template,
        }).then(() => {
          this.$message.success("保存成功");
          this.goBack();
        });
      } else {
        createApproveTemplate(template).then(() => {
          this.$message.success("保存成功");
          this.goBack();
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.layout-body {
  // min-width: 980px;
  flex: 1;
  overflow: auto;
}

/deep/ .el-step {
  .is-success {
    color: #2a99ff;
    border-color: #2a99ff;
  }
}

.err-info {
  max-height: 180px;
  overflow-y: auto;
  & > div {
    padding: 5px;
    margin: 2px 0;
    width: 220px;
    text-align: left;
    border-radius: 3px;
    background: rgb(242 242 242);
  }
  i {
    margin: 0 5px;
  }
}
</style>
