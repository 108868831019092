<template>
  <div class="node">
    <div class="node-body">
      <div class="process-end">
        流程开始
      </div>
    </div>
    <div class="node-footer">
      <div class="btn">
        <insert-button @insertNode="type => $emit('insertNode', type)"></insert-button>
      </div>
    </div>
  </div>
</template>

<script>
import InsertButton from '../../InsertButton.vue'

export default {
  name: "root",
  components: {InsertButton},
  props:{
  },
  computed:{
  },
  data() {
    return {
    }
  },
  methods: {
    validate() {
      return false
    }
  }
}
</script>

<style lang="less" scoped>

.node {
  padding: 0 55px 0;
  width: 220px;

  .node-body {
    max-height: 120px;
    position: relative;
  }

  .node-footer {
    position: relative;

    .btn {
      width: 100%;
      display: flex;
      height: 70px;
      padding: 20px 0 0;
      justify-content: center;
    }

    /deep/ .el-button {
      height: 32px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      margin: auto;
      width: 2px;
      height: 100%;
      background-color: #CACACA;
    }
  }
}

.process-end{
  width: 60px;
  margin: 0 auto;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: small;
  color: #747474;
  background-color: #f2f2f2;
  box-shadow: 0 0 10px 0 #bcbcbc;
  text-align: center;
  position: relative;
}
</style>
