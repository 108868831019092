<template>
  <div>
    <div class="header">
      <div class="title" @click="exit()">
        <span class="el-icon-arrow-left"></span>
        {{ title }}
      </div>
      <el-menu
        :default-active="value"
        active-text-color="#409eff"
        mode="horizontal"
      >
        <el-menu-item index="baseSetting" disabled>① 基础信息</el-menu-item>
        <el-menu-item index="processDesign" disabled>② 审批流程</el-menu-item>
        <!-- <el-menu-item index="proSetting" disabled>③ 更多设置</el-menu-item> -->
      </el-menu>
      <div class="publish">
        <el-button size="small" @click="last()" v-if="value !== 'baseSetting'"
          >上一步</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="next()"
          v-if="value !== 'processDesign'"
          >下一步</el-button
        >
        <!-- <el-button size="mini" @click="preview"><i class="el-icon-view"></i>预览</el-button> -->
        <el-button
          size="small"
          type="primary"
          v-if="value == 'processDesign'"
          @click="publish"
          >保存</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LayoutHeader",
  props: {
    value: {
      type: String,
      default: "baseSetting",
    },
    title: {
      type: String,
      default: "新建审批流",
    },
  },
  data() {
    return {};
  },
  computed: {
    setup() {
      return this.$store.state.workflow.design;
    },
    step() {
      if (this.value == "baseSetting") return 0;
      else if (this.value == "processDesign") return 1;
      else if (this.value == "proSetting") return 2;
      return 0;
    },
  },
  mounted() {
    // console.log(document.body.offsetWidth)
    // if (document.body.offsetWidth <= 970) {
    //   this.$msgbox.alert("本设计器未适配中小屏幕，建议您在PC电脑端浏览器进行操作")
    // }
    // this.listener()
  },
  methods: {
    publish() {
      this.$emit("validate", () => {
        this.$emit("publish");
      });
    },
    next() {
      this.$emit("validate", () => {
        if (this.value === "baseSetting") {
          this.to("processDesign");
        } else if (this.value === "processDesign") {
          this.to("proSetting");
        }
      });
    },
    last() {
      if (this.value === "processDesign") {
        this.to("baseSetting");
      } else if (this.value === "proSetting") {
        this.to("processDesign");
      }
    },
    to(path) {
      this.$emit("input", path);
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    exit() {
      // this.$confirm('修改的内容将不会被保存，是否直接退出 ?', '提示', {
      //   confirmButtonText: '退出',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //window.location.reload()
      //this.$store.commit('clearTemplate')
      this.$emit("exit");
      // })
    },
    // listener() {
    //   window.onunload = this.closeBefore()
    //   window.onbeforeunload = this.closeBefore()
    //   //window.on('beforeunload',this.closeBefore())
    // },
    // closeBefore() {
    //   // alert("您将要离开本页")
    //   return false
    // },
    // preview() {
    //   this.$emit('preview')
    //   //this.
    //   this.viewCode = true;
    // },
  },
};
</script>
<style lang="less" scoped>
/deep/ .header {
  // min-width: 980px;
  position: relative;

  .el-menu {
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .title {
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1000;
    font-size: 16px;
    cursor: pointer;
  }

  .publish {
    position: absolute;
    top: 15px;
    right: 20px;
    z-index: 1000;
  }

  .back {
    position: absolute;
    z-index: 1000;
    top: 10px;
    left: 20px;
    font-size: small;

    span {
      i {
        border-radius: 10px;
        padding: 7.8px;
        font-size: 20px;
        color: #ffffff;
        margin: 0 10px;
      }
    }
  }
}
.bb {
  border-bottom: 1px solid #dcdfe6;
}
.el-menu-item.is-disabled {
  opacity: 1;
  cursor: default;
}
</style>
