<template>
  <div class="base-setup">
    <div class="page-sub-title">基本信息</div>
    <div class="flex mb-20">
      <div class="flex-1">
        <div class="mb-20">
          <div class="mb-10">
            <span class="c-red">*</span>
            审批流名称
          </div>
          <el-input v-model="setup.approveName" size="small" placeholder="请输入审批流名称" maxlength="16" show-word-limit></el-input>
        </div>
        <!-- <div v-if="!!setup.templateId">
          <div class="mb-10">审批流编号</div>
          <el-input v-model="setup.templateId" size="small" disabled></el-input>
        </div> -->
      </div>
      <div class="flex-1 ml-20">
        <div class="mb-10">审批流描述</div>
        <el-input placeholder="请输入审批流描述" v-model="setup.approveDesc" type="textarea" show-word-limit :rows="5" resize="none" maxlength="256" size="small"></el-input>
      </div>
    </div>
    <div class="page-sub-title">触发场景</div>
    <div>
      <div class="mb-10 flex">
        <div class="flex-1 mr-10">
          <el-select class="w100" v-model="setup.approveObject" size="small" placeholder="请选择主体对象" disabled>
            <el-option v-for="o in objects" :key="o.k" :value="o.k" :label="o.v"></el-option>
            <!-- <el-option value="CLUE" label="线索"></el-option> -->
          </el-select>
        </div>
        <div class="flex-1 mr-10">
          <el-select class="w100" v-model="setup.approveAction" size="small" placeholder="请选择动作" disabled>
            <el-option v-for="o in actions" :key="o.code" :value="o.code" :label="o.msg"></el-option>
          </el-select>
        </div>
        <div class="flex-1">
          <el-select class="w100" v-model="setup.approveActionField" size="small" placeholder="请选择被操作字段" v-if="fields.length > 0" disabled>
            <el-option v-for="o in fields" :key="o.code" :value="o.code" :label="o.msg + ( o.desc ? '(' + o.desc + ')' : '')"></el-option>
          </el-select>
        </div>
      </div>
      <!-- <conditionSetting v-model="setup.approveConditionList"></conditionSetting> -->
    </div>
    <!-- <div class="page-sub-title mt-20">审批展示字段</div>
    <div class="mb-20">
      <el-button size="small" style="width: 50%" @click="showParamsDialog">编辑展示字段</el-button>
    </div> -->
    <div class="page-sub-title">高级配置</div>
    <div>
      <div class="mb-10 flex flex-center">
        <div class="flex-1">
          <el-checkbox v-model="setup.allowRevoke">允许发起人撤回审批</el-checkbox>
        </div>
        <div class="flex flex-center flex-1 ml-20">
          <span class="mr-10">流程期限</span>
          <el-input class="flex-1 mr-10" size="small" type="number" onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))" v-model="setup.timeLimitNum" placeholder="请输入整数">
            <template slot="append">天</template>
          </el-input>
          <el-tooltip content="超过期限审批流即失效；若选择1天，在审批发起当天的24：00前需要完成审批">
            <span class="el-icon-question"></span>
          </el-tooltip>
          <!-- <el-select size="small" v-model="setup.timeLimitUnit">
            <el-option :value="0" label="天"></el-option>
            <el-option :value="1" label="小时"></el-option>
          </el-select> -->
        </div>
      </div>
      <!-- <div class="mb-20">
        <el-checkbox v-model="setup.nodeFinishNotice">审批过程中将每个节点结果发给发起人</el-checkbox>
      </div>
      <div>
        <el-checkbox v-model="setup.allFinishNotice">审批全流程结束后将结果发给发起人</el-checkbox>
      </div> -->
    </div>

    <!-- <paramsDialog :visible.sync="paramsVisible" :items="setup.listFieldList" @confirm="setApproveListField"></paramsDialog> -->
  </div>
</template>

<script>
// import conditionSetting from '../conditionSetting.vue'
// import paramsDialog from '../paramsDialog.vue'
import { get_key_value } from '@/api/common'
import {
  getApproveActionList,
} from '@/api/approve'

export default {
  name: "FormBaseSetting",
  components: {
    // conditionSetting,
    // paramsDialog
  },
  data() {
    return {
      // paramsVisible: false,
      objects: [], // 对象
      actions: [], // 动作
      fields: [], // 字段
    }
  },
  computed: {
    setup() {
      return this.$store.state.workflow.design
    }
  },
  watch: {
    async 'setup.approveObject'() {
      await this.getActions()
      if (this.setup.approveAction) {
        this.getFields(this.setup.approveAction)
      }
    },
    'setup.approveAction'(val) {
      if (val) {
        this.getFields(val)
      }
    }
  },
  created() {
  },
  async mounted(){
    await this.getOption()
    if (this.setup.approveObject) {
      await this.getActions()
    }
    if (this.setup.approveAction) {
      this.getFields(this.setup.approveAction)
    }
  },
  methods: {
    async getOption() {
      const res = await get_key_value({
        type: 'APPROVE_OBJECT'
      })
      this.objects = res.records || []
    },
    async getActions() {
      const res = await getApproveActionList({
        approveObject: this.setup.approveObject
      })
      this.actions = res || []
      console.log('getActions',this.setup.approveAction, this.actions)
    },
    getFields(val) {
      const item = this.actions.find(o => o.code == val)
      this.fields = item?.fieldList || []
      console.log('getFields', this.setup.approveActionField, this.fields)

      const needMsgFlag = item?.needMsgFlag || false
      const msgTemplateType = item?.msgTemplateType || ''
      this.$store.commit('workflow/setNeedMsgFlag', needMsgFlag)
      this.$store.commit('workflow/setMsgTemplateType', msgTemplateType)
    },
    // showParamsDialog() {
    //   if (!this.setup.approveObject) {
    //     return this.$message.warning('审批流主体对象未设置')
    //   }
    //   if (!this.setup.approveAction) {
    //     return this.$message.warning('审批流动作未设置')
    //   }
    //   if (!this.setup.approveActionField && this.fields.length > 0) {
    //     return this.$message.warning('审批流被操作字段未设置')
    //   }
    //   this.paramsVisible = true
    // },
    // setApproveListField(items) {
    //   this.setup.listFieldList = items.concat()
    //   console.log(this.setup.listFieldList)
    // },
    validate(){
      let err = []
      if (!this.setup.approveName){
        err.push('审批流名称未设置')
      }
      if (!this.setup.approveObject) {
        err.push('审批流主体对象未设置')
      }
      if (!this.setup.approveAction) {
        err.push('审批流动作未设置')
      }
      if (!this.setup.approveActionField && this.fields.length > 0) {
        err.push('审批流被操作字段未设置')
      }
      // if (!(+this.setup.timeLimitNum > 0 && Math.floor(this.setup.timeLimitNum) === (+this.setup.timeLimitNum))) {
      //   err.push('流程期限必须大于0且为整数')
      // }
      return err
    }
  }
}
</script>

<style lang="less" scoped>
.base-setup {
  overflow: auto;
  margin: 0 auto;
  background: #ffffff;
  margin-top: 10px;
  padding: 15px 20px;
  max-width: 1000px;
}
.w100{
  width: 100%;
}
</style>
