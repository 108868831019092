<template>
  <div class="default-node">
    <div class="mb-20">
      <div class="label">
        <span class="c-red">*</span>
        条件名称
      </div>
      <el-input size="small" v-model="selectedNode.name" placeholder="请输入条件名称"></el-input>
    </div>
    <div class="mb-20">
      <div class="label">
        <span class="c-red">*</span>
        进入节点条件
      </div>
      <el-button class="border-btn" style="width: 100%" size="small" @click="showConditionDialog">编辑条件</el-button>
      <div class="mt-20">
        <el-table :data="nodeProps.conditionConfig.condition" border>
          <el-table-column prop="conditionObjectName" label="对象"></el-table-column>
          <el-table-column prop="conditionOperateName" label="条件"></el-table-column>
          <el-table-column prop="value" label="值">
            <template slot-scope="scope">
              <span>{{randerValue(scope.row.value)}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog
      :visible.sync="conditionVisible"
      title="编辑条件"
      width="800px"
      modal-append-to-body
      append-to-body
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div v-if="conditionVisible">
        <conditionSetting v-model="conditionList"></conditionSetting>
      </div>
      <div slot="footer" class="text-center">
        <el-button size="small" @click="closeConditionDialog(false)">取消</el-button>
        <el-button size="small" type="primary" @click="closeConditionDialog(true)">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import conditionSetting from '../../conditionSetting.vue'
export default {
  name: "Condition",
  components: {
    conditionSetting
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      conditionVisible: false,
      conditionList: []
    }
  },
  computed: {
    setup() {
      return this.$store.state.workflow.design
    },
    selectedNode() {
      return this.$store.state.workflow.selectedNode
    },
    nodeProps() {
      return this.$store.state.workflow.selectedNode.props
    },
  },
  methods: {
    showConditionDialog() {
      if (!this.setup.approveObject) return this.$message.warning('请先选择基础信息-触发场景-主体对象')
      this.conditionVisible = true
      this.conditionList = JSON.parse(JSON.stringify(this.nodeProps.conditionConfig.condition))
      console.log(this.conditionList)
      if (this.conditionList.length == 0) {
        this.conditionList.push({
          conditionObject: '',
          conditionObjectName: '',
          conditionOperate: '',
          conditionOperateName: '',
          valueType: '',
          value: ''
        })
      }
    },
    closeConditionDialog(isSave) {
      if (isSave) {
        const items = this.conditionList.filter(o => {
          if (o.valueType == 'SPECIAL') {
            return !(o.conditionObject && o.conditionOperate && o.valueFmt.length > 0)
          } else if (o.valueType == 'NUMBER') {
            return !(o.conditionObject && o.conditionOperate && o.value !== '')
          } else if (o.valueType == 'DATE') {
            return !(o.conditionObject && o.conditionOperate && o.value !== '')
          } else {
            return !(o.conditionObject && o.conditionOperate)
          }
        })
        if (items.length > 0) {
          return this.$message.warning('条件未填写完整')
        }
        this.nodeProps.conditionConfig.condition = JSON.parse(JSON.stringify(this.conditionList))
        console.log(this.nodeProps.conditionConfig.condition)
      }
      this.conditionVisible = false
      this.conditionList = []
    },
    randerValue(value) {
      let arr = []
      const valueArr = JSON.parse(value)
      valueArr.map(o => {
        if (o.childOperations?.length > 0) {
          o.childOperations.map(v => {
            if (v.childOperations?.length > 0) {
              arr = arr.concat(v.childOperations.map(vv => vv.value))
            } else {
              arr.push(v.value)
            }
          })
        } else {
          arr = arr.concat([o.value])
        }
      })
      return arr.join('、')
    }
  }
}
</script>

<style lang="less" scoped>
.default-node{
  color: #72767b;
  .select{
    width: 100%;
  }
  .label{
    margin-bottom: 10px;
  }
}
</style>
