var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"default-node"},[_c('div',{staticClass:"mb-20"},[_vm._m(0),_c('el-input',{attrs:{"size":"small","placeholder":"请输入节点名称"},model:{value:(_vm.selectedNode.name),callback:function ($$v) {_vm.$set(_vm.selectedNode, "name", $$v)},expression:"selectedNode.name"}})],1),_c('div',{staticClass:"mb-20"},[_vm._m(1),_c('el-select',{staticClass:"select",attrs:{"size":"small","placeholder":"请选择审批类型"},model:{value:(_vm.nodeProps.mode),callback:function ($$v) {_vm.$set(_vm.nodeProps, "mode", $$v)},expression:"nodeProps.mode"}},[_c('el-option',{attrs:{"value":"OR","label":"或签（一人通过即可）"}}),_c('el-option',{attrs:{"value":"AND","label":"会签（所有人通过才可）"}})],1)],1),_c('div',{staticClass:"mb-20"},[_vm._m(2),_c('el-select',{staticClass:"select mb-10",attrs:{"size":"small","placeholder":"请选择审批人","disabled":""},on:{"change":_vm.handleChangeUserType},model:{value:(_vm.nodeProps.userType),callback:function ($$v) {_vm.$set(_vm.nodeProps, "userType", $$v)},expression:"nodeProps.userType"}},_vm._l((_vm.userTypeList),function(t){return _c('el-option',{key:t.key,attrs:{"value":t.key,"label":t.value}})}),1),(_vm.nodeProps.userType == 'ROLE')?_c('el-select',{staticClass:"select",attrs:{"size":"small","multiple":"","clearable":"","placeholder":"请选择审批人","disabled":""},on:{"change":_vm.handleChangeRole},model:{value:(_vm.nodeProps.role),callback:function ($$v) {_vm.$set(_vm.nodeProps, "role", $$v)},expression:"nodeProps.role"}},_vm._l((_vm.userList),function(o){return _c('el-option',{key:o.key,attrs:{"value":o.key,"label":o.value}})}),1):_vm._e(),(_vm.nodeProps.userType == 'APPOINTOR')?_c('el-cascader',{ref:'conditionValueCascader',staticClass:"select",attrs:{"size":"small","placeholder":"请选择字段","options":_vm.userList,"props":{
        multiple: true,
        label: 'value',
        value: 'key',
        children: 'childOperations'
      },"show-all-levels":false,"filterable":"","clearable":"","disabled":""},on:{"change":_vm.handleChangeValue},model:{value:(_vm.nodeProps.user),callback:function ($$v) {_vm.$set(_vm.nodeProps, "user", $$v)},expression:"nodeProps.user"}}):_vm._e()],1),_c('div',{staticClass:"mb-20"},[_c('el-checkbox',{model:{value:(_vm.nodeProps.autoPass),callback:function ($$v) {_vm.$set(_vm.nodeProps, "autoPass", $$v)},expression:"nodeProps.autoPass"}},[_vm._v("当前节点审批人与流程发起人为同一个人，自动通过")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',{staticClass:"c-red"},[_vm._v("*")]),_vm._v(" 节点名称 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',{staticClass:"c-red"},[_vm._v("*")]),_vm._v(" 审批类型 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"label"},[_c('span',{staticClass:"c-red"},[_vm._v("*")]),_vm._v(" 审批人 ")])
}]

export { render, staticRenderFns }