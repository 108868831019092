<template>
  <node :title="config.name" :show-error="showError" :content="content" :error-info="errorInfo"
        @selected="$emit('selected')" @delNode="$emit('delNode')" @insertNode="type => $emit('insertNode', type)"
        placeholder="请设置审批人" header-bgc="#ff943e" header-icon="el-icon-s-check"/>
</template>

<script>
import Node from './Node'

export default {
  name: "Approval",
  props:{
    config:{
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {Node},
  data() {
    return {
      showError: false,
      errorInfo: '',
    }
  },
  computed:{
    content(){
      const props = this.config.props
      switch (props.userType){
        case "APPOINTOR":
          if (props.userConfig.userList.length > 0){
            let arr = []
            props.userConfig.userList[0].childOperations.map(o => {
              arr = arr.concat(o.childOperations)
            })
            return String(arr.map(o => o.value)).replaceAll(',', '、')
          }else {
            return '请指定审批人'
          }
        case "SUPERIOR":
          return '发起人的上级'
        case "ROLE":
          if (props.userConfig.userList.length > 0){
            return String(props.userConfig.userList[0].childOperations.map(o => o.value)).replaceAll(',', '、')
          }else {
            return '指定角色（未设置）'
          }
        default: return '请设置审批人'
      }
    }
  },
  methods: {
    getFormItemById(id){
      return this.$store.state.design.formItems.find(item => item.id === id)
    },
    //校验数据配置的合法性
    validate(err){
      this.showError = false
      if (!this.config.name){
        this.showError = true
        this.errorInfo = '请设置节点名称'
        err.push(`节点名称不可为空`)
      }
      if (!this.config.props.userType){
        this.showError = true
        this.errorInfo = '请设置审批人'
        err.push(`${this.config.name} 未设置审批人`)
      }
      if (!this.config.props.mode){
        this.showError = true
        this.errorInfo = '请设置审批类型'
        err.push(`${this.config.name} 未设置审批类型`)
      }
      try {
        this.showError = !this[`validate_${this.config.props.userType}`](err)
        return this.showError
      } catch (e) {
        return true;
      }
    },
    validate_APPOINTOR(err){
      if(this.config.props.userConfig.userList.length > 0){
        return true;
      }else {
        this.errorInfo = '请指定审批人员'
        err.push(`${this.config.name} 未指定审批人员`)
        return false
      }
    },
    validate_SUPERIOR(){
      return true;
    },
    validate_ROLE(err){
      if (this.config.props.userConfig.userList.length <= 0){
        this.errorInfo = '请指定负责审批的角色'
        err.push(`${this.config.name} 未指定审批角色`)
        return false
      }
      return true;
    },
  }
}
</script>

<style scoped>

</style>
